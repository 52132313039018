import PageLinkComponent from '@/components/page/linkComponent';
import { axiosClient } from '@/data';
import { auth } from '@/firebase/client';
import useUserInfo from '@/providers/auth/useUserInfo';
import { unreadMessagesCountAtom } from '@/utils/atoms';
import { isProduction } from '@/utils/config';
import { Chat as ChatIcon } from '@mui/icons-material';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { useAtom } from 'jotai/index';
import { useRouter } from 'next/router';
import { useAsyncEffect } from 'rooks';

export default function ChatButton( { clientView = false }: { clientView?: boolean } ) {
	const [ unreadMessagesCount, setUnreadMessagesCount ] = useAtom( unreadMessagesCountAtom );
	const { staff } = useUserInfo();
	const router = useRouter();
	const isChatRoute = router.asPath.split( '/' )[ 2 ] === 'chat';
	
	const chatSettings = staff?.company?.chatSettings;
	const internalActive = chatSettings?.internal;
	const externalActive = chatSettings?.external;
	
	// useAsyncEffect( async () => {
	// 	if ( isProduction ) return;
	//
	// 	const { data } = await axiosClient.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/user/chat/countUnreads`, {
	// 		uid      : auth.currentUser?.uid,
	// 		companyId: clientView ? '' : staff?.company?.id,
	// 	} );
	//
	// 	setUnreadMessagesCount( data.unreadMessages );
	// 	return;
	// }, [] );
	
	return null;
	
	if ( !internalActive && !externalActive && !clientView ) return null;
	
	return (
		<Tooltip
			placement='bottom'
			title={staff ? 'Chat with other team members or clients' : 'Chat with the merchant'}>
			<Badge
				badgeContent={unreadMessagesCount}
				color='error'
				invisible={unreadMessagesCount <= 0}>
				<IconButton
					component={PageLinkComponent}
					color={isChatRoute ? 'primary' : 'default'}
					href={`${clientView ? '/client/chat' : '/dashboard/chat'}${router.query.id
						? `/${router.query.id}`
						: ''}`}>
					<ChatIcon/>
				</IconButton>
			</Badge>
		</Tooltip>
	);
}
