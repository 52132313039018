import { blurryBackground } from '@/components/elevationScroll';
import { queryGraphQL } from '@/data';
import { ClientRead } from '@/data/management/client.graphql';
import { sendReactNativeNotification } from '@/helpers/reactNativePostMessage';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import useUserInfo from '@/providers/auth/useUserInfo';
import type { QueryClientReadArgs } from '@/types/schema';
import { AppBar, BottomNavigation, BottomNavigationAction, Box, Container, Stack } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import SelectCompanyMenu from '../desktop/companyMenu';
import GlobalSearchButton from '../desktop/globalSearchButton';
import ProfileButton from '../desktop/profileButton';
import { getAuthMobileMenuItems, getClientMobileWebMenuItems, getCompanyMobileMenuItems } from '../menuItems';

export default function MobileNavigation( { children } ) {
	const { t } = useTranslation();
	const router = useRouter();
	const { height } = useWindowSize();
	const { user, staff } = useUserInfo();
	const { isCloverDevice } = useGetDeviceInfo();
	const clientMobileMenuItems = getClientMobileWebMenuItems();
	const companyMobileMenuItems = getCompanyMobileMenuItems();
	const authMobileMenuItems = getAuthMobileMenuItems();
	
	const route = router.route.split( '/' );
	
	const navigationItems = useMemo( () => {
		if ( route[ 1 ] === 'dashboard' ) {
			return companyMobileMenuItems;
		}
		if ( route[ 1 ] === 'client' ) {
			return clientMobileMenuItems;
		}
		if ( !user ) {
			return authMobileMenuItems;
		}
		return [];
	}, [ user, route ] );
	
	const index = useMemo( () => {
		for ( let i = 0; i < navigationItems.length; ++i )
			if ( navigationItems[ i ].href === router.route ) return i;
	}, [ router.route ] );
	
	return (
		<Box>
			<Box
				pl='env(safe-area-inset-left)'
				minHeight={`min(calc(100vh - 56px - env(safe-area-inset-top) - env(safe-area-inset-bottom)), ${height}px)`}
				pr='env(safe-area-inset-right)'>
				{user && (
					<AppBar
						color='inherit'
						position='static'
						sx={{
							pt          : 'env(safe-area-inset-top) !important',
							border      : 0,
							borderBottom: 1,
							borderColor : 'divider',
							bgcolor     : 'background.default',
						}}>
						<Container maxWidth='xl'>
							<Stack alignItems='center' direction='row' spacing={2}>
								{staff ? <SelectCompanyMenu/> : <Box p={1}/>}
								<Box display='flex' flexGrow={1}/>
								{route[ 1 ] !== 'driver' && route[ 1 ] !== 'client' && <GlobalSearchButton/>}
								<ProfileButton/>
							</Stack>
						</Container>
					</AppBar>
				)}
				{children}
			</Box>
			<Box height='calc(env(safe-area-inset-bottom) + 56px)'/>
			{navigationItems.length !== 0 && (
				<AppBar
					position='fixed'
					color='inherit'
					sx={{
						top         : 'auto',
						bottom      : 0,
						displayPrint: 'none',
						border      : 0,
						borderTop   : 1,
						borderColor : 'divider',
						bgcolor     : 'transparent',
					}}>
					<BottomNavigation
						showLabels
						value={index}
						sx={{
							height: 'calc(env(safe-area-inset-bottom) + 56px)',
							pl    : 'env(safe-area-inset-left)',
							pr    : 'env(safe-area-inset-right)',
							pb    : 'env(safe-area-inset-bottom)',
							...blurryBackground( isCloverDevice ),
						}}
						onChange={async ( e, value ) => {
							sendReactNativeNotification( { type: 'haptic', value: 'light' } );
							const pathname = navigationItems[ value ].href;
							
							let query;
							if ( !user && router.query.clientId ) {
								const { clientRead } = await queryGraphQL<QueryClientReadArgs>( {
									query    : ClientRead,
									variables: { id: router.query.clientId as string },
								} );
								
								const newClient = clientRead?.name || clientRead?.contact;
								
								if ( newClient ) {
									query = {
										redirect : pathname,
										firstName: newClient?.split( ' ' )[ 0 ],
										lastName : newClient?.split( ' ' )[ 1 ],
										email    : clientRead?.email,
									};
								}
							}
							
							await router.push( { pathname, query } );
						}}>
						{navigationItems.map( ( item, index ) => (
							<BottomNavigationAction
								key={index}
								label={t( `commerce:${item.name.toLowerCase()}` )}
								icon={
									item.Badge ? (
										<item.Badge>
											<item.Icon/>
										</item.Badge>
									) : <item.Icon/>
								}>
							</BottomNavigationAction>
						) )}
					</BottomNavigation>
				</AppBar>
			)}
		</Box>
	);
}
