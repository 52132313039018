import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import Loading from '@/components/loading';
import PageWrapper from '@/components/page/wrapper';
import { mutateGraphQL } from '@/data';
import { LineItemWrite } from '@/data/management/lineItem.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import SubTotalSection from '@/pages/p/store/cart/subTotal';
import { useOrderPublicRead, useStorePublicRead } from '@/pages/p/store/context';
import StorePublicLineItemsModal from '@/pages/p/store/lineItemsModal';
import StorePaymentDrawer from '@/pages/p/store/payment';
import { useModal, useModalControls } from '@/providers/modal';
import { type MutationLineItemWriteArgs } from '@/types/schema';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
	Avatar,
	Box,
	Button,
	Chip,
	Collapse,
	Divider,
	ListItem,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';

export default function StoreCart() {
	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();
	const { showModal } = useModal();
	const { closeModal } = useModalControls();
	const { store } = useStorePublicRead();
	const { order, isLoading } = useOrderPublicRead();
	const [ localLineItems, setLocalLineItems ] = useState( order?.lineItems || [] ); // Local state for line items
	
	useEffect( () => {
		setLocalLineItems( order?.lineItems || [] );
	}, [ order ] );
	
	useEffect( () => {
		if ( !Boolean( localLineItems.length ) ) {
			closeModal( 'cartDrawer' );
		}
	}, [ order?.lineItems?.length ] );
	
	if ( isLoading ) return <Loading/>;
	
	if ( !localLineItems.length || !order ) return null;
	
	return (
		<PageWrapper
			hideBack
			modalWrapper
			boxProps={{ sx: { width: { sm: 500 }, height: '100%' } }}
			primary={(
				<Stack spacing={2}>
					<Typography variant='h1'>Cart</Typography>
					<SubTotalSection order={order}/>
					<Button
						color='primary'
						size='large'
						sx={{ width: '100%', my: 1 }}
						onClick={async () => {
							if ( order.grandTotal! < store.minDelivery ) {
								enqueueSnackbar( `Your order\'s total is less than the store\'s minimum order of ${currencyFormat( store.minDelivery )}` );
								return;
							}
							closeModal( 'cartDrawer' );
							showModal( StorePaymentDrawer, {
								variant: 'drawer',
								onClose: ( event, reason ) => stayOpen( event, reason ),
								sx     : {
									'& .MuiDrawer-paper': {
										border     : 'none',
										borderLeft : 1,
										borderColor: 'divider',
									},
								},
							} );
						}}>
						Proceed to Checkout
					</Button>
				</Stack>
			)}>
			<Box>
				<TransitionGroup>
					{localLineItems.map( ( lineItem, index ) => {
						const modifiers = ( lineItem.modifierGroups || [] )
							.map( ( mGroup ) => mGroup.modifiers )
							.flat()
							.filter( ( modifier ) => modifier?.externalId && lineItem.metadata?.[ modifier.externalId ] );
						
						return (
							<Collapse key={lineItem.id} timeout={500}>
								<Stack
									id={lineItem.id}
									sx={{
										':hover'      : { cursor: 'pointer', bgcolor: 'divider' },
										'width'       : '100%',
										'transition'  : '.2s',
										'borderRadius': 2,
										'p'           : 1,
									}}
									onClick={() => showModal( StorePublicLineItemsModal, { maxWidth: 'sm' }, { orderItem: lineItem } )}>
									<ListItem disablePadding>
										<ListItemText
											primary={`${lineItem.name}`}
											primaryTypographyProps={{ variant: 'h6', sx: { fontWeight: 500 } }}
											secondary={`${currencyFormat( lineItem.price || 0 )} x ${lineItem.quantity}`}
										/>
										<Stack direction='row' alignItems='center' spacing={1}>
											<AsyncLoadingButton
												color='error'
												variant='contained'
												className='cartDeleteIcon'
												onClick={async ( e ) => {
													e.stopPropagation();
													
													// Remove the item from local state immediately
													setLocalLineItems( ( prevItems ) => prevItems.filter( ( item ) => item.id !== lineItem.id ) );
													
													// Call the API to remove the item in the background
													await mutateGraphQL<MutationLineItemWriteArgs>( {
														mutation : LineItemWrite,
														variables: { id: lineItem.id, remove: true },
													} );
													
													// Re-fetch data after API call completes
													await queryClient.invalidateQueries( [ 'orderPublicRead' ] );
												}}>
												<DeleteIcon sx={{ fontSize: 18 }}/>
											</AsyncLoadingButton>
											<Avatar
												src={lineItem?.images?.[ 0 ] || lineItem?.image || '/images/coming-soon.png'}
												variant='rounded'
											/>
										</Stack>
									</ListItem>
									{Boolean( lineItem.modifierGroups?.length ) && (
										<Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
											{modifiers.map( ( modifier, modIndex ) => modifier && (
												<Chip
													key={modIndex}
													variant='alpha'
													color='info'
													sx={{ width: 'fit-content', overflowWrap: 'break-word', mr: 1, mt: 1 }}
													label={(
														<Stack direction='row' alignItems='center' spacing={.5}>
															<Typography>{modifier.name}</Typography>
															{modifier.isPercent ? (
																<Typography>
																	{`(${modifier.value}%) ` + currencyFormat( modifier.value * ( lineItem.price * lineItem.quantity / 100 ) )}
																</Typography>
															) : (
																<Typography sx={{ textTransform: 'lowercase' }}>
																	{`${lineItem.metadata?.[ modifier.externalId! ] > 1
																		? `(${currencyFormat( modifier.value )} x ${lineItem.metadata[ modifier.externalId! ]})`
																		: `${currencyFormat( modifier.value )}`}`}
																</Typography>
															)}
														</Stack>
													)}
												/>
											) )}
										</Box>
									)}
									{lineItem.note && (
										<Typography mt={1} color='text.secondary' className='ellipsisText'>
											Note: {lineItem.note}
										</Typography>
									)}
								</Stack>
								{localLineItems?.length - 1 !== index && <Divider sx={{ m: 1 }}/>}
							</Collapse>
						);
					} )}
				</TransitionGroup>
			</Box>
		</PageWrapper>
	);
}
