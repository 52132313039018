import { useAppSelector } from '@/store/hooks';
import { useEffect, useState } from 'react';
import { useIntervalWhen } from 'rooks';

const EMERGENCY_BANNER_HOST = process.env.NEXT_PUBLIC_EMERGENCY_BANNER_HOST;

function EmergencyBannerIframe() {
	const theme = useAppSelector( ( { main } ) => main.theme );
	const [ height, setHeight ] = useState( 0 );
	const [ refreshKey, setRefreshKey ] = useState( 0 );
	
	useIntervalWhen( () => {
		setRefreshKey( refreshKey + 1 );
	}, 30000 ); // Refresh the iframe every 30 seconds
	
	function receiveIframeMessage( event ) {
		if ( event.origin === EMERGENCY_BANNER_HOST ) {
			setHeight( event.data.height );
		}
	}
	
	useEffect( () => {
		window.addEventListener( 'message', receiveIframeMessage );
		return () => {
			window.removeEventListener( 'message', receiveIframeMessage );
		};
	}, [] );
	
	return (
		<iframe
			key={refreshKey}
			src={`${EMERGENCY_BANNER_HOST}?theme=${theme}`}
			style={{ display: 'flex', width: '100%', border: 'none', height }}
		/>
	);
}

export default EmergencyBannerIframe;
